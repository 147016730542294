interface ImageLogoProps {
    width?: number;
    src: string;
    fallbackSrc: string;
    alt: string;
    title: string;
}

const ImageLogo: React.FC<ImageLogoProps> = ({ width, src, fallbackSrc, alt, title }) => {
    const imageId = `image-logo-${Math.random().toString(36).substr(2, 9)}`;
    const handleImageError = () => {
        const img = document.getElementById(imageId) as HTMLImageElement;
        if (img) {
            img.src = fallbackSrc;
        }
    }

  return (
    <img
      id={imageId}
      width={width}
      src={src}
      alt={alt}
      title={title}
      onError={handleImageError}
    />
  );
}

export default ImageLogo
